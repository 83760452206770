<template>
  <div
    class="gift-layout"
    :class="{ 'gift-layout--christmas': isChristmas }"
  >
    <BegHeaderComponent v-if="isBegHeader" />
    <GiftHeaderComponent v-else />
    <slot />
    <div class="gift-layout__bg-image" />
  </div>
</template>
<script>

import GiftHeaderComponent from '@/components/GiftHeaderComponent/GiftHeaderComponent.vue';
import BegHeaderComponent from '@/components/BegHeaderComponent/BegHeaderComponent.vue';

export default {
    name: 'GiftLayout',
    components: {
        GiftHeaderComponent,
        BegHeaderComponent,
    },
    props: {
        isChristmas: {
            type: Boolean,
            default: false,
        },
        isBegHeader: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" src="./GiftLayout.scss" />
