<template>
  <div class="gift-header">
    <img
      :src="require('images/gift-voicemod-icon.svg')"
      class="gift-header__logo--desktop"
      alt="gift voicemod icon"
      data-testid="gift-logo-img"
    >
    <img
      :src="require('images/gift-voicemod-logo-mobile.svg')"
      class="gift-header__logo--mobile"
      alt="gift voicemod icon"
      data-testid="gift-logo-img-mobile"
    >
    <div class="gift-header__profile">
      {{ email }}
      <button
        class="gift-header__profile__button"
        data-testid="gift-eye-button"
        @click="toggleMask"
      >
        <img
          v-if="isEmailMasked"
          :src="require('images/eye-visible-icon.svg')"
          alt="visible eye icon"
          data-testid="gift-eye-visible"
        >
        <img
          v-else
          :src="require('images/eye-invisible-icon.svg')"
          alt="invisible eye icon"
          data-testid="gift-eye-invisible"
        >
      </button>
    </div>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import { maskEmail } from '@/utils';
import { AccountClient } from '@/services/models/accounts';
import { CookieService } from '@/services/cookie';

export default {
    name: 'GiftHeaderComponent',
    data() {
        return {
            isEmailMasked: true,
        };
    },
    computed: {
        ...mapState('user_info_store', {
            userEmail: (state) => state.userInfo.email,
        }),
        email() {
            return this.isEmailMasked ? maskEmail(this.userEmail) : this.userEmail;
        },
    },
    async mounted() {
        if (!this.userEmail) {
            const userId = CookieService.getCookie('userId');
            if (userId) {
                try {
                    const { data } = await AccountClient.getUserInfo(userId);
                    this.axn_setUserInfo({ userId, ...data });
                } catch (error) {
                    Sentry.captureMessage('Can\'t retrieve user information in Gift Header');
                    Sentry.captureException(error);
                }
            }
        }
    },
    methods: {
        ...mapActions('user_info_store', [
            'axn_setUserInfo',
        ]),
        toggleMask() {
            this.isEmailMasked = !this.isEmailMasked;
        },
    },
};
</script>

<style lang="scss" src="./GiftHeaderComponent.scss" />
