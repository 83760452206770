<template>
  <div class="beg-header">
    <img
      :src="require('images/gift-voicemod-icon.svg')"
      class="gift-header__logo--desktop"
      alt="gift voicemod icon"
      data-testid="gift-logo-img"
    >
    <router-link
      v-if="userEmail"
      class="beg-header__link"
      target="_blank"
      :to="{ path: ROUTER_PATHS.HOME }"
    >
      {{ userEmail }}
    </router-link>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import { ROUTER_PATHS } from '@/router/paths';
import { AccountClient } from '@/services/models/accounts';
import { CookieService } from '@/services/cookie';
export default {
    computed: {
        ...mapState('user_info_store', {
            userEmail: (state) => state.userInfo.email,
            state: (state) => state,
        }),
    },
    created() {
        this.ROUTER_PATHS = ROUTER_PATHS;
    },
    async mounted() {
        if (!this.userEmail) {
            const userId = CookieService.getCookie('userId');
            if (userId) {
                try {
                    const { data } = await AccountClient.getUserInfo(userId);
                    this.axn_setUserInfo({ userId, ...data });
                } catch (error) {
                    Sentry.captureMessage('Can\'t retrieve user information in Gift Header');
                    Sentry.captureException(error);
                }
            }
        }
    },
    methods: {
        ...mapActions('user_info_store', [
            'axn_setUserInfo',
        ]),
        toggleMask() {
            this.isEmailMasked = !this.isEmailMasked;
        },
    },
};
</script>
<style lang="scss" src="./BegHeaderComponent.scss" />